<template>
  <main class="Home">
    <section v-if="data" class="Home_Projects">
      <ul>
        <li
          v-for="project in data.projects"
          :key="project?._id"
          class="Home_Item"
          :class="{ Video: project?.video }"
        >
          <ElementsTextLink
            v-if="project?._id"
            link-type="internalLink"
            route="projects-slug"
            :slug="project?.slug.current"
          >
            <ElementsMediaBaseFigure
              v-if="project?.titleImage"
              :image="project?.titleImage"
              class="Home_Image imagelink"
              use-hotspot
            />
            <ElementsMediaBaseLoop
              v-if="project?.video"
              :video="project?.video"
              :posterImage="project?.video?.posterImage"
              class="Home_Video imagelink"
              use-hotspot
            />
            <div class="Home_Project_Info">
              <span v-if="!project?.client" class="text-base Home_Project_Info_Title">
                {{ project?.title }}
              </span>
              <span v-if="project?.client" class="text-base Home_Project_Info_Title">
                {{ project?.client[0].title }}
              </span>
              <span v-if="project.subtitle" class="text-base Home_Project_Info_Year">
                {{ project?.subtitle }}
              </span>
            </div>
          </ElementsTextLink>
        </li>
      </ul>
    </section>
  </main>
</template>

<script setup>
import { homeQuery } from '@/queries/contentQueries'

// get data
const mainStore = useMainStore()
const data = await useSanityData({
  query: homeQuery,
})


// meta
usePageHead({
  title: '',
})
</script>

<style scoped>
.Home {
  padding: var(--big-margin) var(--base-padding) var(--big-margin);
  @media (min-width: token(width.md)) {
    padding: 0 1rem var(--big-margin) 1rem;
  }
}

.Home_Projects > ul {
  display: grid;
  gap: 9rem 0;
  grid-template-columns: repeat(10, minmax(0, 10vw));

  @media (min-width: token(width.md)) {
  grid-template-columns: repeat(11, minmax(0, calc(100vw / 11)));
    grid-template-rows: auto;
    gap: calc(var(--big-margin) * 2) var(--big-margin);
  }
}

.Home_Item {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  /* width: 100%; */
  /* justify-self: start; */

  @media (min-width: token(width.md)) {
    padding: 0 17.5%;
  }
}

.Home_Item > a {
  width: 100%;
}

.Home_Item span {
  display: block;
  margin-top: 0.5rem;
}

.Home_Image,
.Home_Video {
  position: relative;
  width: 100%;
}

.Home_Item.Video {
  @media (min-width: token(width.md)) {
    margin-top: var(--big-margin);
    margin-bottom: var(--base-margin);
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
  }
}

.Home_Image:deep(picture),
.Home_Image:deep(img) {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Home_Project_Info {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin: var(--base-padding) 0 0 0;
}

.Home_Project_Info_Title {
  margin: 0 var(--base-margin) 0 0;
}

/* Grid */
@media (min-width: 1px) {
  .Home_Item:nth-child(2n + 1) {
    grid-column-start: 1;
    grid-column-end: 11;
  }
  .Home_Item:nth-child(2n + 2) {
    grid-column-start: 3;
    grid-column-end: 11;
  }
  .Home_Item:nth-child(4n + 2) {
    grid-column-start: 1;
    grid-column-end: 9;
  }
  /* .Home_Item:nth-child(n+1) {
    grid-column-start: 1;
    grid-column-end: 9;
  }
    .Home_Item:nth-child(2n+1) {
    grid-column-start: 3;
    grid-column-end: 11;
  } */
}

@media (min-width: token(width.md)) {
  .Home_Item:first-child {
    padding: 0 17.5% 0 5%;
    grid-column-start: 0;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .Home_Item:nth-child(2) {
    padding: 0 0 0 17.5%;
    grid-column-start: 7;
    grid-column-end: 12;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .Home_Item:nth-child(3) {
    grid-column-start: 2;
    grid-column-end: 7;
    grid-row-start: 2;
    grid-row-end: 4;
  }

  .Home_Item:nth-child(4) {
    padding: 0 0 0 20%;
    grid-column-start: 7;
    grid-column-end: 12;
    grid-row-start: 3;
    grid-row-end: 5;
  }

  .Home_Item:nth-child(5) {
    padding: 0 17.5% 0 5%;
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 4;
    grid-row-end: 7;
  }

  .Home_Item:nth-child(6) {
    grid-column-start: 6;
    grid-column-end: 12;
    grid-row-start: 5;
    grid-row-end: 8;
  }

  .Home_Item:nth-child(7) {
    padding: 0 25% 0 0;
    grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 7;
    grid-row-end: 9;
  }

  .Home_Item:nth-child(8) {
    grid-column-start: 7;
    grid-column-end: 12;
    grid-row-start: 8;
    grid-row-end: 10;
  }

  .Home_Item:nth-child(9) {
    grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 9;
    grid-row-end: 11;
  }

  .Home_Item:nth-child(10) {
    padding: 0 0 0 10%;
    grid-column-start: 8;
    grid-column-end: 12;
    grid-row-start: 10;
    grid-row-end: 12;
  }

  .Home_Item:nth-child(11) {
    padding: 0 17.5% 0 5%;
    grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 11;
    grid-row-end: 13;
  }

  .Home_Item:nth-child(12) {
    padding: 0 10% 0 10%;
    grid-column-start: 7;
    grid-column-end: 12;
    grid-row-start: 12;
    grid-row-end: 14;
  }

  .Home_Item:nth-child(13) {
    padding: 0 10% 0 0;
    grid-column-start: 2;
    grid-column-end: 7;
    grid-row-start: 13;
    grid-row-end: 15;
  }

  .Home_Item:nth-child(14) {
    padding: 0 0 0 0%;
    grid-column-start: 7;
    grid-column-end: 12;
    grid-row-start: 14;
    grid-row-end: 16;
  }

  .Home_Item:nth-child(15) {
    padding: 0 0 0 17.5%;
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 15;
    grid-row-end: 18;
  }

  .Home_Item:nth-child(16) {
    padding: 0 5% 0 15%;
    grid-column-start: 6;
    grid-column-end: 12;
    grid-row-start: 16;
    grid-row-end: 19;
  }

  .Home_Item:nth-child(17) {
    padding: 0 10% 0 0;
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 18;
    grid-row-end: 20;
  }

  .Home_Item:nth-child(18) {
    padding: 0 7.5% 0 2.5%;
    grid-column-start: 7;
    grid-column-end: 12;
    grid-row-start: 19;
    grid-row-end: 21;
  }

  .Home_Item:nth-child(19) {
    grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 20;
    grid-row-end: 22;
  }

  .Home_Item:nth-child(20) {
    grid-column-start: 6;
    grid-column-end: 11;
    grid-row-start: 21;
    grid-row-end: 24;
  }
}
</style>
